.breadcrumbs {
	margin-top: 5px;
	padding: 15px 0;
}

.breadcrumbs a {
	font-family: Roboto;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
	text-decoration: none;
}

.breadcrumbs a:not(:first-child) {
	margin-left: 15px;
	position: relative;
}

.breadcrumbs a:not(:first-child)::after {
	position: absolute;
	display: block;
	content:">";
	font-size: 12px;
	top: 0;
	left: -10px;
	color: var(--black);
}

.breadcrumbs a:not(.active) {
	color: var(--black);
	@apply --transitionColor;
}

.breadcrumbs .active,
.breadcrumbs a:hover,
.breadcrumbs a:active {
	color: var(--textGreyLight);
}

@media (width <= 767px) {
	.breadcrumbs {
		margin-top: 40px;
		border-top: none;
		text-align: center;
		padding-left: 15px;
		padding-right: 15px;
	}
}