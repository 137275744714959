.site-main {
	display: flex;
}





@media (width <= 767px) {
	.site-main {
		flex-wrap: wrap;
	}
}