.site-footer  {
	margin-top: -57px;
	width: 100%;
	background-color: rgba(128,128,128,.2);
}
.site-footer a {
	color: var(--textGreyDemiLight);
	font-family: Roboto;
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	text-decoration: none;
	padding: 20px 80px;
	display: block;
}





@media (width <= 479px) {
	.site-footer a { text-align: center; }
}