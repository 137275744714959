.content {
	width: calc(100% - 275px);
	padding: 20px 40px 60px;
	background-color: var(--bgGrey);
}

.content-heading {
	color: black;
	font-family: Roboto;
	font-size: 21px;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 40px;
}





@media (width <= 767px) {
	.content {
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;
	}

	.content-heading {
		text-align: center;
	}
}