/* variables */
:root {
	--blank: transparent;
	--accent: #6bb501;
	--accentHover: #78cc02;
	--accentActive: #64ab02;
	--inActive: #bdbdbd;
	--textGreyLight: #aaa7a7;
	--textGrey: #474444;
	--textGreyDark: #4a4a4a;
	--textGreyDemiLight: #9d9b9b;
	--borderGrey: #caced1;
	--bgGrey: #f3f3f3;
	--black: #141515;
}

/* properties */
:root {
	--transitionOpacityStart {
		will-change: opacity;
		opacity: 1;
		transition: opacity .3s;
	};
	--transitionOpacityEnd {
		opacity: .7;
		transition: opacity .3s;
	};
	--activeShadow {
		box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.18);
	};

}