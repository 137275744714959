.request-inner {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.request-inner--main {
	flex-basis: calc(100% - 385px);
}

.request-inner--sidebar-right {
	flex-basis: 345px;
	background-color: white;
	border: 1px solid var(--borderGrey);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.27);
	display: flex;
	flex-wrap: wrap;
	padding: 5px 15px 20px;
}

.request-inner--sidebar-right .form-formats span {
	flex-basis: calc(100% - 20px);
	position: relative;
	margin-top: 45px;
	text-align: left;
	margin: 0 10px;
	color: var(--textGrey);
	text-transform: uppercase;
	font-size: 14px;
	font-family: Roboto;
	display: block;
	width: 100%;
}

.request-inner--sidebar-right .form-formats span::before {
	position: absolute;
	top: -25px;
	color: black;
	content: attr(data-label);
	text-transform: uppercase;
	font-weight: 500;
	font-size: 14px;
	font-family: Roboto;
	width: 100%;
}

.form-formats {
	padding-bottom: 15px;
	width: 100%;
}

.form-formats:not(:last-child) {
	border-bottom: 1px solid var(--borderGrey);
}

.request-inner--sidebar-right .form-formats .format-name {
	color: black;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 14px;
	font-family: Roboto;
	width: 100%;
	margin-top: 20px;
}

.request-inner--sidebar-right .form-formats .format-value {
	margin-top: 10px;
}

.request-inner-wrapper {
	background-color: white;
	border: 1px solid var(--borderGrey);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.27);
	margin-bottom: 40px;
}

.request-body {
	padding: 25px;
}

.request-body p {
	color: black;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 400;
}

.request-current-status {
	padding: 20px 25px;
	background-color: var(--bgGrey);
}

.request-current-status__heading {
	color: black;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 400;
}

.request-current-status__status {
	font-family: Roboto;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
}

.request-current-status__status--ready {
	color: var(--accent);
	margin: 0 10px;
}

.request-current-status__button {
	text-decoration: none!important;
	display: inline-block;
	color: white!important;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	text-transform: uppercase;
	background-color: #bdbdbd;
	border-radius: 2px;
	box-shadow: 0 1px 0 #6bb501;
	padding: 5px 10px;
	text-align: center;
	transition: background-color .3s;
	position: relative;
}

.request-current-status__button:not(:disabled):hover {
	background-color: #78cc02;
}

.request-current-status__button:not(:disabled):active,
.request-current-status__button:not(:disabled):visited:active {
	background-color: #64ab02;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.18);
	top: 1px;
}

.request-change-status {
	padding: 20px 25px;
}

.request-change-status__select {
	width: calc(50% - 10px);
	display: inline-block;
	float: left;
	position: relative;
	margin-bottom: 20px;
}

.request-change-status__select:first-child {
	margin-right: 20px;
}

.request-change-status__select select {
	padding: 10px 10px 8px;
	color: var(--textGrey);
	text-transform: uppercase;
	font-weight: 400;
	font-size: 14px;
	font-family: Roboto;
	-webkit-appearance: none;
}

.request-change-status__select::after {
	position: absolute;
	top: 2px;
	right: 2px;
	width: 36px;
	height: 36px;
	background-color: #FFF;
	background: url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAQCAwMDAgQDAwMEBAQEBQkGBQUFBQsICAYJDQsNDQ0LDAwOEBQRDg8TDwwMEhgSExUWFxcXDhEZGxkWGhQWFxb/2wBDAQQEBAUFBQoGBgoWDwwPFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhb/wgARCAAJAA8DAREAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAgUGCP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAANNhl8OT/8QAKBAAAAQCCQUBAAAAAAAAAAAAAQIDBQQGAAcREhRBUnSyExU1N5Hx/9oACAEBAAE/AIqNdqmH9VVxXjHSQ3NcT9c15VdlVNqzMkI/OUswT3Wy+kmp7xzTKMNe7M2kVFJWOtAS4lazLSH6NbPrV62ZqMnhoTbk40//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AB//2Q==') center center no-repeat;
	content: "";
	pointer-events: none;
}

.request-change-status__textarea {
	width: 100%;
}

.request-change-status__textarea textarea {
	width: 100%;
	min-height: 100px;
	resize: vertical;
	padding: 10px 10px 8px;
	color: var(--textGrey);
	text-transform: uppercase;
	font-weight: 400;
	font-size: 14px;
	font-family: Roboto;
}

.request-change-status__textarea textarea:focus {
	outline: 0;
	border-color: #6bb501;
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(107, 181, 1, 0.6);
}

.request-change-status__buttons {
	margin-top: 20px;
}

.request-change-status__buttons a,
.request-change-status__buttons button {
	padding: 10px 20px;
	margin-right: 20px;
	border: none;
}

.request-change-status__buttons button:disabled {
	opacity: 0.8;
	cursor: no-drop;
	box-shadow: none;
}

.request-history {
	padding-bottom: 40px;
}

.request-history .content-heading {
	margin-bottom: 20px;
}

.request-history .table-heading {
	flex-wrap: wrap;
}

.request-history .table-row {
	align-items: flex-start;
}

.request-history .table-heading span:not(:last-child), 
.request-history .table-row span:not(:last-child) {
	flex-basis: calc(10% - 20px);
}

.request-history .table-heading span:last-child,
.request-history .table-row span:last-child {
	flex-basis: calc(60% - 20px);
}

.request-history .table-row span:last-child {
	text-align: left;
	text-transform: none;
}

.request-history .history-status-indicator--new {
	font-weight: bold;
	color: var(--accent);
}

.request-fields,
.complaint-fields {
	width: 100%;
	max-width: 800px;
	background-color: #FFF;
	margin-bottom: 40px;
}

.request-fields select, 
.request-fields textarea,
.complaint-fields textarea {
	padding: 10px 10px 8px;
	color: var(--textGrey);
	text-transform: uppercase;
	font-weight: 400;
	font-size: 14px;
	font-family: Roboto;
}

.request-fields textarea,
.complaint-fields textarea {
	resize: vertical;
}

.request-fields .low-textarea textarea {
	height: 45px;
}

.request-fields .high-textarea textarea,
.complaint-fields textarea {
	height: 200px;
}

.request-fields .request-change-status__buttons a,
textarea .request-change-status__buttons a {
	margin-top: 10px;
}




@media (width <= 1499px) {
	.request-inner {
		flex-wrap: wrap;
	}

	.request-inner--main,
	.request-inner--sidebar-right {
		flex-basis: 100%;
	}

	.request-inner--sidebar-right {
		order: -1;
		margin-bottom: 40px;
	}

	.request-inner--sidebar-right > span {
		flex-basis: calc(25% - 20px);
	}

	.request-inner .bordered {
		padding-bottom: 0;
		border-bottom: none;
	}

	.request-history .table-heading span:not(:last-child), 
	.request-history .table-row span:not(:last-child) {
		flex-basis: calc(30% - 20px);
	}

	.request-history .table-heading span:last-child,
	.request-history .table-row span:last-child {
		flex-basis: 100%;
	}
}





@media (width <= 1199px) {
	.request-inner--sidebar-right > span { flex-basis: calc(50% - 20px); }
}





@media (width <= 991px) {
	.request-inner--sidebar-right > span { flex-basis: calc(100% - 20px);	}
	.request-change-status__select { width: 100%; }

	.request-history .table-heading span:not(:last-child), 
	.request-history .table-row span:not(:last-child) {
		flex-basis: 100%;
	}

	.request-history .table-heading span:last-child,
	.request-history .table-row span:last-child {
		flex-basis: 100%;
	}
}





@media (width <= 767px) {
	.request-inner--sidebar-right > span { flex-basis: calc(50% - 20px); }

	.request-history .table-heading span:not(:last-child), 
	.request-history .table-row span:not(:last-child) {
		flex-basis: 100%;
	}

	.request-history .table-heading span:last-child,
	.request-history .table-row span:last-child {
		flex-basis: 100%;
	}
	
	.request-fields .sidebar-select:nth-child(odd) {
		margin-left: 0;
		width: 100%;
	}

	.request-fields .sidebar-select {
		width: 100%;
	}

}





@media (width <= 479px) {
	.request-inner--sidebar-right > span { flex-basis: calc(100% - 20px);	}
	.request-current-status { text-align: center;	}
	.request-current-status__button{	margin-top: 10px;	}
	.request-change-status__buttons {	text-align: center;	}
	.request-change-status__buttons a {	margin-left: 20px; }
}