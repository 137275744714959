.site-header {
	position: relative;
	background-color: white;
	padding: 20px 25px 20px 20px;
	box-shadow: 0 6px 11px rgba(0, 0, 0, 0.14);
}

.header-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
}

.header-logo {
	margin-top: -5px;
	flex-grow: 1;
}

.header-logo img {
	vertical-align: bottom;
}

.header-logo__text {
	color: #8e8f93;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 400;
	text-transform: uppercase;
	vertical-align: super;
	display: inline-block;
}

.header-phone .main-tel {
	margin-top: 0;
	margin-right: 25px;
	text-decoration: none;
	color: var(--textGreyDark);
}

.header-buttons a {
	display: inline-block;
	width: 229px;
	height: 44px;
	background-color: var(--inActive);
	border-radius: 2px;
	box-shadow: 0 1px 0 var(--accent);
	color: white;
	font-family: Roboto;
	font-size: 18px;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
	padding: 12px 0 10px 30px;
	margin-left: 15px;
	transition: background-color .3s;
	position: relative;
	text-decoration: none;
}

.header-buttons a:hover {
	background-color: var(--accentHover)!important;
}

.header-buttons a:active {
	top: 1px;
}

.header-buttons a:active,
.header-buttons a:visited:active {
	background-color: var(--accentActive);
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.18);
}

.header-buttons a::after {
	position: absolute;
	top: 9px;
	left: 20px;
	content: "";
	width: 30px;
	height: 30px;
}

.header-buttons #makeComplaint::after {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAABIFBMVEUAAAAEBAQAAAAAAAABAQEAAAACAgL+/v78/Pz////////+/v739/f5+fn+/v78/Pz4+Pj19fX09PT29vbs7Ozr6+vm5ubFxcXCwsL7+/vd3d3Gxsbt7e3////+/v78/Pz+/v77+/v7+/v4+Pj9/f309PT9/f3w8PD7+/vw8PD8/Pzt7e3n5+fh4eHb29v7+/vs7OzX19fi4uLv7++ysrLy8vLAwMC7u7vz8/NjY2Pq6uo6Ojr19fX////+/v79/f3+/v77+/v+/v729vb+/v709PT5+fn8/Pzy8vL7+/v9/f319fX9/f3w8PD8/PzZ2dnPz8/7+/vS0tLi4uK6urq0tLSkpKTe3t7///+Ojo7///93d3enp6f////V1dX///+a2goVAAAAX3RSTlMAAgURDAgP4qH26t2qSu3NtpuVgnJubD46KysiEvjm08+8tqynmpiKhoJ/fWJZWVZNS0Q1MS8qIB4bGBYM8N/ZxcXCsKuhn5KNd25rZ2NQUEVCPjU0MyonJSQiHhoHBtY21lAAAAGoSURBVCjPbdHlciMxEATgHY0kM8ex4zjMDBe+HDMzQ7//W0QzWjlJJf2rdz5N1ZaUXcvw5+Hvf9ntmVh/WAJQnn97dgs+FWo8mikBpdXz60ivgMJa/y4z/9lrhf6JruoysCEWc1AFduhSX6D+kdnbGGYeA2qUtIapfkBnDBEZ46z4vSFFpTK22btg8ZOM9fwMxZzfoMnWKU7UzmVinD+tY0DaK+j5qLSIO3FmeQWrWgeosDVaqYhDKeJf8UCG9A5tUR0WMZlX5wuYpDDqoGtNFmdVHJu0Po994TY2HeW8lLYzY6vYNWG0gu6IWzgyWd6b2Bfu4uWIN6t/R1zBkfBnNBIba13iY0zbwMZPY5C4iC8U2YxhSdmu4XniTuNHqgX0LIVif5X0rvQyrcu5gznvhJ0fR+GMIjtDqrvAdytd7vcJZk+kkjxpfGKM67KucxPl9+qq/9eBDU43Ld4C5naGmeTk9f2wy+kvMn3+7Rlg6nFxeXEWwMI31idOHvZ5a6EOSaXd47BrVJNbZj7tf9jaOwjF23w3uRzwLFE0SZPrAc1NTAc0dAUvAANKOkaQSsmCAAAAAElFTkSuQmCC');
}

.header-buttons #createRequest::after {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAABPlBMVEUAAAAAAAAAAAADAwMAAAAAAAABAQEAAAACAgL////+/v7+/v739/fz8/P29va1tbX4+Pj////////9/f3+/v7+/v709PT19fXu7u7p6en8/Pze3t7w8PD6+vri4uLHx8fCwsLd3d0AAAD+/v7+/v76+vr7+/v7+/v7+/v39/f39/f29vb9/f3z8/P9/f36+vr9/f3w8PD09PTx8fH9/f3w8PD5+fnt7e3z8/Ps7Ozl5eXl5eXd3d3X19fNzc3y8vKwsLD///+SkpLHx8fz8/Nubm7V1dVeXl46Ojr4+Pjs7Oz////+/v7+/v77+/v+/v7+/v7+/v78/Pz29vb4+Pj09PT7+/vm5ub9/f39/f3w8PD8/Pz6+vrt7e38/Pzm5ubZ2dnHx8f7+/vPz8/7+/v5+fn09PTZ2dnr6+v///8qSOt8AAAAaXRSTlMAAQYEEQkMEA7q4tq2jEoyLvj2597Nm5WEcmNZWFdEPTorFO3Rz8W8tq+rqaijoJ+YmI+HgoB/fW1ta2NcSz84KiMjIB4dGBcWEQbx7+XUxcKrkpGIeHdvbmdjXl5UUFBQTkZFQisYFA1u4+yqAAAB6ElEQVQoz22S50IbMRCELWklnRvuxtgGG9N7b6H3QHrvvc77v0B2lYsNgflzc/ftSLcrJa7p4jB/eJm4XT9WHxUAJCff/LwJ808EVcZHi0Bx9fd1qF4CqbX2XSI63a2y/66u0kVgg1ms3AwwqHr0KUa+EfnIWWtd5IkGgD71j/ah0CbvrDYibZ1nfu+X+ktNEg2KrFYqvCou8DSH+RhvIkORNortu0peScD60/sYViGcRiuygarZ8EuBr+C5ETuMsmcqVi+gGTuXQ1qzNW+xFImRj/0Yiq31KRwr/rSCASvLfK2/b45juVmv70tlNIkhExLblsvy6CrNPRg3jU+Cs9jSjM9eLGdrY8jUss8+GMY2wxsx3kJWsOZhdJbwpUPeasFpHAjew1jANvKdOewQj1bSxyg5wVFJBsCz4oIZbMtsZe9NVJ2RRWtYMDITHsZaqSVQFnuAljRk7FER+3Hf50cu7nsdE96q0OErpM5UwDbsGw4RB6FSGUdTqJwEq3WX3vGydohTBsmPwln8uFwHNkjC8fFQFZgYvEiITl6XOUuR0C5vPAQKj+cXZ0cBTOUoHHFQGAnRzvQIROX+PerROC8X8Lz9ubGbIyLvdKA9rqUgyMulVD3avYDWucg5ayX6v5RUsIy5kvwDKMpLP3227uwAAAAASUVORK5CYII=');
}

.header-login {
	text-align: left;
	margin-left: 25px;
}

.header-login-headingTop {
	font-family: Roboto;
	font-size: 18px;
	color: black;
	font-weight: 400;
	display: block;
}

.header-login-headingBottom {
	font-family: Roboto;
	font-size: 18px;
	color: black;
	font-weight: 500;
	position: relative;
	padding-right: 30px;
}

.header-login-headingBottom::after {
	position: absolute;
	top: -1px;
	right: 14px;
	content: "|";
	font-weight: 400;
}

.header-login #logOutButton {
	font-family: Roboto;
	font-size: 18px;
	color: #184a8d;
	font-weight: 400;text-decoration: none;
	@apply --transitionOpacityStart;
}

.header-login #logOutButton:hover {
	@apply --transitionOpacityEnd;
}





@media (width <= 1500px) and (width >= 1200px){
	.header-wrapper { flex-wrap: nowrap; }
	.header-logo__text { display: none; }

	.header-phone .main-tel {
		margin-right: 0;
		font-size: 20px;
	}

	.header-buttons a {	width: 210px;	}
	.header-buttons a:first-child { margin-left: 5px; }
	.header-buttons a:last-child { margin-left: 5px; }
	.header-buttons a::after { left: 10px; }
	.header-login { margin-left: 10px; }
}





@media (width <= 1199px) {
	.header-logo {
		margin-bottom: 10px;
		flex-basis: 50%;
	}

	.header-phone {
		flex-basis: 30%;
		text-align: right;
	}

	.header-phone .main-tel {
		margin-right: 0;
		margin-top: -10px;
	}

	.header-buttons {	margin-left: 12px; }
	.header-buttons a {
		margin-left: 0;
		margin-right: 10px;
	}

	.header-login { text-align: right; }
}





@media (width <= 991px) and (width >= 768px) {
	.header-phone .main-tel { font-size: 20px; }
	.header-buttons a {	width: 210px;	}
	.header-buttons a::after { left: 10px; }
	.header-login { margin-left: 0; }
}





@media (width <= 767px) {
	.header-logo,
	.header-phone  {
		flex-basis: 100%;
		text-align: center;
		margin-bottom: 10px;
	}

	.header-logo__text { display: none; }

	.header-buttons {
		flex-basis: 100%;
		text-align: center;
		margin: 0;
	}

	.header-buttons a { margin: 10px; }

	.header-login {
		flex-basis: 100%;
		text-align: center;
		margin: 0;
	}

}