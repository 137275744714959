.table-heading {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	align-items: center;
	padding: 0 15px;
}

.table-heading > span {
	color: black;
	font-family: Roboto;
	font-size: 13px;
	font-weight: 500;
	text-transform: uppercase;
	margin: 0 10px;
	text-align: center;
	flex-grow: 1;
}

.table-heading--8 > span {
	flex-basis: calc(100% / 8);
}

.table-heading--9 > span {
	flex-basis: calc(100% / 9);
}

.table-heading--sortable {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	@apply --transitionOpacityStart;
}

.table-heading--sortable:hover {
	@apply --transitionOpacityEnd;
}

.table-heading--sortable span {
	margin: 0 2px;
}

.table-heading--sortable__ico::before {
	content: "↓↓";
	color: black;
	font-size: 14px;
	display: block;
}

.table-heading--sortable__ico--up {
	transform: rotateZ(180deg);
}

.table-heading--sortable__ico--down {
	transform: rotateZ(0deg);
}

.table-row {
	background-color: white;
	border: 1px solid #ebecf0;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.27);
	transition: box-shadow .3s;
	padding: 20px 15px;
	margin: 5px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-decoration: none;
}

.table-row:hover {
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.27);
	text-decoration: none!important;
}

.table-row > span {
	flex-grow: 1;
	text-align: center;
	margin: 0 10px;
	font-family: Roboto;
	font-size: 14px;
	text-transform: uppercase;
	color: var(--textGrey);
}

.table-row--8 span {
	flex-basis: calc(100% / 8);
}

.table-row--9 span {
	flex-basis: calc(100% / 9);
}

.status-indicator {	
}

.status-indicator span {
	border: 1px solid;
	padding: 5px 10px;
}

.status-indicator .status-indicator--new {
	color: var(--accent);
	border-color: var(--accent);
	font-weight: 500;
}

.request-is-draft {
	background-color: #E6E6E6;
	position: relative;
}

.request-is-draft::before {
	position: absolute;
	width: 22px;
	height: 22px;
	content: "";
	top: 0;
	left: 0;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAAQlBMVEUAAABDQ0FDQ0FCQkJDQ0FDQ0FDQ0FCQkFCQkFEREBDQ0FDQ0FDQ0FDQ0FDQ0FDQ0JDQ0JDQ0FDQ0JDQ0OXl5eSkpKrK1rJAAAAFHRSTlMAGwqtUUMjZ5Uyg3FejDq4xXmf7cXxNi4AAACtSURBVBjTfdFLbgQhDEVRG2ww2PyqOvvfapRO+EQq9R2eCfCA12Pwgocmu93BDndussPLr1p2f4x8R5oNj4svCL8hYObJ1ZtY/InKGL0ujgTvqjAXj1//maQqW1lsGlJCElam4uaRPmgpQ0pSsuOCF5YuObMS+4N9IItaiaOmlhY3B5Ak8hAMffMVWs/dj4zABwswvd8IENrmW5mJyCya3Gkv2M4Fn/f++DuPfQOxyw1NSj/M1wAAAABJRU5ErkJggg==');
}





@media (width <= 1499px) {
	.table-heading {
		justify-content: flex-start;
	}
	.table-heading > span:not(.table-heading--sortable) {
		display: none;
	}

	.table-heading .table-heading--sortable {
		flex-grow: 0;
	}

	.table-row {
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
		padding-top: 5px;
	}

	.table-row > span {
		flex-basis: calc(25% - 20px);
		text-align: left;
		margin-top: 45px;
		position: relative;
		flex-grow: 0;
	}

	.table-row > span::before {
		position: absolute;
		top: -25px;
		content: attr(data-label);
		color: black;
		font-family: Roboto;
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
	}
}





@media (width <= 1199px) {
	.table-row > span {
		flex-basis: calc(50% - 20px);
	}
}





@media (width <= 991px) {
	.table-heading {
		justify-content: center;
	}

	.table-heading > span {
		flex-basis: auto;
	}

	.table-row > span::before {
		font-size: 12px;
		top: -25px;
	}
}





@media (width <= 479px) {
	.table-row > span {
		flex-basis: calc(100% - 20px);
	}

	.table-row > span::before {
		font-size: 14px;
		top: -25px;
	}
}






