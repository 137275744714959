.sidebar {
	width: 275px;
	background-color: white;
	border-right: 1px solid var(--borderGrey);
	padding-bottom: 70px;
}

.control-tabs {
	width: 100%;
	padding: 45px 0 45px 10px;
	border-bottom: 1px solid var(--borderGrey);
}

.control-tabs a {
	font-family: Roboto;
	font-size: 16px;
	text-transform: uppercase;
	color: black;
	font-weight: 400;
	display: block;
	border: 1px solid var(--blank);
	background-color: var(--blank);
	border-radius: 2px 0px 0px 2px;
	padding: 17px 13px;
	text-decoration: none;
	opacity: 1;
	transition: background-color .3s, opacity .3s;
}

.control-tabs .active-tab {
	color: white;
	font-weight: 700;
	background-color:var(--accent);
}

.control-tabs .active-tab:hover {
	background-color:var(--accentHover);
}

.control-tabs .active-tab:active {
	background-color:var(--accentActive);
	@apply --activeShadow;
}

.control-tabs a:not(.active-tab):hover {
	opacity: .7;
	transition: background-color .3s, opacity .3s;
}

.filters {
	width: 100%;
	padding: 45px 30px;
}

.filters-heading {
	color: black;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 15px;
}

.filters-heading:nth-of-type(2) {
	margin-bottom: 30px;
}

.filters-subheading {
	color: var(--textGrey);
	font-family: Roboto;
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.form-control {
	background-color: white;
	border-radius: 0;
	border: 1px solid var(--borderGrey);
	height: auto;
}

.form-control:focus {
	border-color: var(--accent);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(107, 181, 1, 0.6);
}

.sidebar-search {
	position: relative;
	margin-bottom: 30px;
}

.sidebar-search__button {
	position: absolute;
	top: 8px;
	right: 10px;
	content: "";
	width: 20px;
	height: 20px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAclBMVEUAAABrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFrtQFhiL2HAAAAJXRSTlMA+h/16OHOvXZzWQ7CtbCoGAPYxZWOaVRMRj47NzMrFhOhnod/Nlq01wAAAJtJREFUGNOVkEcOwkAMAHfTC+mdkBDK/P+LQJwC4gJzGo0tWbL6G8OPnKLsPlptMRMbe/NZcbbaa8i9rkmAJFtism74oAdpqYVuRV3wxE4aexL14LrHcLt4ETMs9FE0hrtYFoE7rwYaZPx0oAjGvjIhypbrLgCmBuxR2vnAhjO8NdMrc8t261AJt1cLlArTSW1UYDZfn4wPrfqBB5gyD9S2a5GyAAAAAElFTkSuQmCC');
}

.filters select,
.filters input {
	padding: 10px 10px 8px;
	color: var(--textGrey);
	font-family: Roboto;
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
}

.filters select {
	appearance: none;
}

.sidebar-select {
	position: relative;
}

.sidebar-select::after {
	position: absolute;
	top: 33px;
	right: 2px;
	width: 36px;
	height: 34px;
	background-color: #FFF;
	background: url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAQCAwMDAgQDAwMEBAQEBQkGBQUFBQsICAYJDQsNDQ0LDAwOEBQRDg8TDwwMEhgSExUWFxcXDhEZGxkWGhQWFxb/2wBDAQQEBAUFBQoGBgoWDwwPFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhb/wgARCAAJAA8DAREAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAgUGCP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAANNhl8OT/8QAKBAAAAQCCQUBAAAAAAAAAAAAAQIDBQQGAAcREhRBUnSyExU1N5Hx/9oACAEBAAE/AIqNdqmH9VVxXjHSQ3NcT9c15VdlVNqzMkI/OUswT3Wy+kmp7xzTKMNe7M2kVFJWOtAS4lazLSH6NbPrV62ZqMnhoTbk40//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AB//2Q==') center center no-repeat;
	content: "";
	pointer-events: none;
}

.alert-message {
	display: block;
}






@media (width <= 767px) {
	.sidebar {
		width: 100%;
		border-right: none;
		border-bottom: 1px solid var(--borderGrey);
	}

	.control-tabs {
		border-bottom: 1px solid var(--accent);
		padding-bottom: 0;
		padding-top: 30px;
		display: flex;
	}

	.control-tabs a {
		width: 180px;
		border-radius: 2px 2px 0px 0px;
		text-align: center;
		margin-right: 20px;
	}

	.sidebar-select {
		width: 48%;
		display: inline-block;
		float: left;		
	}

	.sidebar-select:nth-child(odd) {
		margin-left: 4%;
	}
}





@media (width <= 479px) {

	.sidebar {
		padding-bottom: 0;
	}
	
	.control-tabs {
		padding-left: 0;
	}

	.control-tabs a {
		width: 50%;
		margin: 0;
		font-size: 15px;
	}

	.sidebar-select {
		width: 100%;
		float: none;		
	}

	.sidebar-select:nth-child(odd) {
		margin-left: 0;
	}

}