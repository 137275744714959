/* variables */
:root {
	--paginationWidth: 10px;
	--paginationHeight: 10px;
	--paginationBg__Left: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAMAAAC67D+PAAAAP1BMVEUAAAAfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYulmIfAAAAFXRSTlMA+sUNSAbj2LaGfm5bJBoZt66VVzEpO8BwAAAAPUlEQVQI12XKORaAIAxAwZ/EDdS4wP3PyiOUdFMMwJKg5JBkLjlDaRLyUyUi73ab7k/nekz2YcX0I+xgTgNg7AHMPxX0+wAAAABJRU5ErkJggg==');
	--paginationBg__Right: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAMAAAC67D+PAAAAP1BMVEUAAAAfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYulmIfAAAAFXRSTlMA+gfFt0gkGQ7jhn5uW9jXrpRYMBzjVDjJAAAAPklEQVQI12XJOxKAIBAD0KyriCB/7n9WAg0FKTJvEvgKuAczvwR42Xan+TcUMdSb1Jrvvqh4ClktdIoruy8NZSgBxs/DYq0AAAAASUVORK5CYII=');
	--paginationIcon {
		width: var(--paginationWidth);
		height: var(--paginationHeight);
		display: block;
		position: absolute;
		content: "";
		top: 6px;
	}
}

.page-pagination {
	margin: 40px 0;
}

.page-pagination ul {
	list-style: none;
	padding-left: 0;
	display: flex;
	justify-content: center;
	width: 100%;
}

.page-pagination a {
	text-decoration: none;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	background-color: #fafafa;
	border: 1px solid #ebecf0;
	border-radius: 2px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.27);
	padding: 5px 10px;
	color: var(--textGrey);
	margin: 0 3px;
	@apply --transitionOpacityStart;
}

.page-pagination .active a {
	color: #FFF;
	background-color: var(--accent);
	border: 1px solid var(--blank);
	transition: background-color .3s;
}

.page-pagination .active:hover a {
	background-color: var(--accentHover);
}

.page-pagination li:first-child,
.page-pagination li:last-child {
	position: relative;
}



.page-pagination li:first-child a,
.page-pagination li:last-child a {
	border: none;
	box-shadow: none;
	background-color: var(--blank);
	color: var(--accent);	
}

.page-pagination li:not(.disabled):not(.active):hover a {
	@apply --transitionOpacityEnd;
}

.page-pagination li:first-child a { margin-right: 20px; }
.page-pagination li:last-child a { margin-left: 20px; }

.page-pagination li.disabled a {
	color: var(--textGrey);
	cursor: no-drop;
}




@media (width <= 767px) {
	.page-pagination li:first-child::before {
		@apply --paginationIcon;
		background-image: var(--paginationBg__Left);
		left: 0;
		pointer-events: none;
	}

	.page-pagination li:last-child::before {
		@apply --paginationIcon;
		background-image: var(--paginationBg__Right);
		right: 0;
		pointer-events: none;
	}

	.page-pagination span { display: none; }
}