@media (width >= 768px) {
	.start-footer {
		background-image: url(../img/start-footer--full.jpg);
	}
}





@media (width >= 992px) {
	.start-header {
		background-size: contain;
	}
}





html,
body {
	height: 100%;
}

body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 320px;
}

main { flex: 1 0 auto; }
footer { flex: 0 0 auto; }

.start-header {
	background-image: url(../img/start-header--full.jpg);
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	min-height: 229px;
}

.header-start-content {
	display: flex;
	justify-content: center;
	margin-top: 30px;
}

.header-start-content--logo {
	flex-basis: 315px;
	margin-top: 20px;
	margin-right: 70px;
}

.header-start-content--logo > a span {
	color: var(--accent);
	font-family: Roboto;
	font-size: 10.5px;
	font-weight: 700;
	line-height: 30px;
	text-transform: uppercase;
	display: block;
	width: 220px;
	margin-left: 13px;
	margin-top: -5px;
}

.header-start-content--logo > a {
	text-decoration: none;
}

.header-start-content--logo p {
	font-family: Roboto;
	font-weight: 700;
	font-size: 14px;
	color: var(--accent);
	margin-left: 9px;
	padding-left: 25px;
	margin-bottom: 5px;
	position: relative;
}

.header-start-content--logo p::before {
	position: absolute;
	top: -2px;
	left: 0px;
	width: 20px;
	height: 20px;
	content: "";
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAVFBMVEUAAACWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBmWyBlM5dIVAAAAG3RSTlMAbRLn2+G3Wu67elj1Ms/Fo4o4sK6OLSMeAgEa6XtDAAAAjklEQVQY05WQWQ7DMAhEcbwndrbunfvfsyJYtJWlSn1f1hMGBvqXwQRnXTDDh4sego/qRrwZm5uAtKidpJ9F2mlDwx59DZCJrlpqWAZgJqoqM0sHlDutKh1Le3yJKm2rhB/o8lWZ+XV6Ulxr3QoQZDpzloQLYNqejDe3B+2J95REQplzaon67P2Vft2z5wUHTRG+joRYQQAAAABJRU5ErkJggg==');
}

.header-start-content--logo p a {
	color: var(--textGreyLight);
	font-weight: 400;
	margin-left: 10px;
	text-decoration: none;
}

.header-start-content--logo p b {
	color: var(--black);
}

.header-start-content--logo p:first-of-type a:last-child {
	position: relative;
	margin-left: 20px;
}

.header-start-content--logo p:first-of-type a:last-child::before {
	position: absolute;
	top: -2px;
	left: -10px;
	content: "|";
}

.header-start-content--slider {
	flex-basis: 670px;
	max-width: 670px;
}

.owl-theme .owl-controls .owl-page span {
	background-color: var(--accent);
	margin-top: -20px;
}

.login {
	text-align: center;
	width: 100%;
	max-width: 430px;
	margin-left: auto;
	margin-right: auto;
}

.login h1 {
	color: var(--black);
	font-family: "Exo 2";
	font-size: 38px;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 40px;
}

.login__form input {
	animation: login-autofill both .5s;
	display: block;
	width: 100%;
	height: 55px;
	background-color: white;
	border: 1px solid  var(--textGreyDemiLight);
	border-radius: 2px;
	margin-bottom: 30px;
	padding: 20px 15px;
	color: var(--textGreyDemiLight);
	font-family: Roboto;
	font-size: 16px;
	font-weight: 400;
	text-transform: uppercase;
}

.login__form input:focus {
	border: 1px solid var(--accent);
	outline-color: var(--accent);
}

@keyframes login-autofill {
	to {
		box-shadow: 0 0 0 99px white inset;
		-webkit-text-fill-color: #9d9b9b;	
	}
}

.login__form #logInButton {
	display: inline-block;
	color: white;
	font-family: "Exo 2";
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
	background-color: var(--accent);
	border: 1px solid var(--accent);
	border-radius: 2px;
	width: 200px;
	padding: 5px;
	text-decoration: none;
	transition: background-color .3s;
}

.login__form #logInButton:hover {
	background-color: #78cc02;
}

.login__form #logInButton:active {
	margin-top: 1px;
	margin-bottom: -1px;
}

.login__form #logInButton:active,
.login__form #logInButton:visited:active {
	background-color: #64ab02;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.18);
}

.main-tel {
	margin-top: 100px;
	display: inline-block;
	color: var(--textGreyDark);
	font-family: Roboto;
	font-size: 24px;
	font-weight: 500;
	text-transform: uppercase;	
	@apply --transitionOpacityStart;
	margin-left: 30px;
	position: relative;	
}

.main-tel:hover {
	@apply --transitionOpacityEnd;
	text-decoration: none;
	color: var(--textGreyDark);
}

.main-tel::after {	
	position: absolute;
	width: 30px;
	height: 30px;
	content: "";
	top: 1px;
	left: -40px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAgVBMVEUAAAC9vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb3bfjWrAAAAKnRSTlMAFgUjVeVc3Xj58snFoo3s6IdpHn5RDsu/mTHXzij1g0OzcDmwO7iqYEhJQr5AAAABVElEQVQoz62T2XKDMAxFr23MvhMIOyRpuuj/P7AY25Nlms50pudBLwdLSJbxXzAnOcpZjonDfpBFSOTFp9gjCovnD5ycolJwAFyUEeXOgz1Q7ipncHM6PNhYYOfitf4EiPjOOxQ1AFsroErfQuoYmohsfpZJAd5ldOSoynUqQ6+HkBnTuiAXSGnDxSUj+VmNeQWXCn043E6JQOk3oOm7LbnXgh9DpiuXQK1s0EFR03KlGqWungRC61SY3s40tS1EkGBj9DgwBUQLDFwWbsDgjdiQsYrvRORbnwxXuiCW2JhPKvbzna/TKulxmm+n4ZL1lkja2oqz8bz72OffZK39851F+2qL0d6Mb/tWmMkte1yBloSd2s1rQgaf0tvMDedA62KzQ2NvLBfWfw3KeoeIhunhvg2NP6gMWcqetsXC+9pd2YtdM7zYVM3LPU+qX19J1uMPfAOAyCFJFiQBUwAAAABJRU5ErkJggg==');
}

.start-footer {	
	background-size: cover;
	background-position: bottom left;
	background-repeat: no-repeat;
	min-height: 165px;
	display: flex;
	flex-direction: column-reverse;
}

.start-footer a {
	display: block;
	text-align: center;
	color: white;
	font-family: Roboto;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 10px;
	text-decoration: none;
}





@media (width <= 1499px) {}





@media (width <= 1199px) {
	.login .main-tel {
		margin-top: 50px;
	}

	.start-footer {
		margin-top: -25px;
	}
}






@media (width <= 991px) {
	.header-start-content--logo {
		margin-right: 0;
		margin-top: 0;
	}
	.start-main {
		margin-top: -50px;
	}
}





@media (width <= 767px) {
	.start-header {
		background-image: url(../img/start-header--768.jpg);
		background-size: cover;
		min-height: 130px;
	}

	.start-main {
		margin-top: 0px;
	}
}






@media (width >= 480px) and (width <= 767px) {
	.start-footer {
		background-image: url(../img/start-footer--768.jpg);
		background-size: contain;
		min-height: 120px;
	}
}





@media (width <= 479px) {

	.start-main {
		padding-left: 15px;
		padding-right: 15px;
	}

	.login h1 {
		margin-top: 50px;
		font-size: 32px;
		margin-bottom: 30px;
	}

	.login .main-tel {
		margin-top: 30px;
	}

	.start-footer {
		background-image: url(../img/start-footer--480.jpg);
		background-size: cover;
		background-position: bottom center;
		min-height: 125px;
	}

	.start-footer a {
		color: #818675;
	}
}